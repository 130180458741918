import { createStore } from 'vuex';

const store = createStore({
    state() {
        return {
            msalConfig: {
                auth: {
                    //baggesenAps 
                    //clientId: '5967433b-c59f-4d66-afb9-3712cd89078d',
                    //authority: 'https://login.microsoftonline.com/11cd9e9a-c4a4-4e3f-8b25-1a8b9d57eb9a',
                    //redirectUri: "https://msrevlicens.timesolutions.dk/",
                    //redirectUri: "http://localhost:50598/",                
                    // timesolutions
                    //clientId: '82deb0b9-f286-4d59-9233-daa065297130',
                    clientId: process.env.VUE_APP_CLIENT_ID,
                    //authority: 'https://login.microsoftonline.com/1ad7f589-868a-4bc4-9977-2361ddf6022b',
                    authority: process.env.VUE_APP_AUTHORITY,
                    //redirectUri: "https://msrevlicens.timesolutions.dk/",
                    redirectUri: process.env.VUE_APP_REDIRECT_URI,
                },
                cache: {
                    cacheLocation: 'localStorage',
                }
            },
            accessToken: '',
            //scope :['5967433b-c59f-4d66-afb9-3712cd89078d/access_as_user'],
            //timesolutions
            //scope: ['82deb0b9-f286-4d59-9233-daa065297130/access_as_user'],
            scope: [process.env.VUE_APP_SCOPE],

        };
    },
    mutations: {
        setAccessToken(state, token) {
            state.accessToken = token;
        }
    }
});

export default store;