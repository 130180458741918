import { createWebHistory, createRouter } from "vue-router";
import CreateLicense from "@/components/CreateLicense.vue";
import FetchLicenses from "@/components/FetchLicenses.vue";
import CreateOldLicense from "@/components/CreateOldLicense.vue";
import FetchOldLicenses from "@/components/FetchOldLicenses.vue"

const routes = [
    //{
    //    path: "/",
    //    name: "Home",
    //    component: Home,
    //},
    //{
    //    path: "/Counter",
    //    name: "Counter",
    //    component: Counter,
    //},
    {
        path: "/CreateLicense",
        name: "CreateLicense",
        component: CreateLicense,
    },
    {
        path: "/FetchLicenses",
        name: "FetchLicenses",
        component: FetchLicenses,
    },
    {
        path: "/CreateOldLicense",
        name: "CreateOldLicense",
        component: CreateOldLicense,
    },
    {
        path: "/FetchOldLicenses",
        name: "FetchOldLicenses",
        component: FetchOldLicenses,
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;

