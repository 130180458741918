<template>
    <h1>Create MS Rev License</h1>
    <div class="container">
        <form v-on:submit.prevent="submitForm">
            <div class="form-group">
                <label class="control-label" for="company">Company to use the license</label>
                <input type="text" class="form-control" id="company" placeholder="TimeSolutions"
                       v-model="form.company">
            </div>
            <div class="form-group">
                <label for="version">Which version should the license handle</label>
                <select name="version" class="form-control" id="version" v-model="form.version">
                    <option value="2021/1">2021/1</option>
                    <option value="2021/2">2021/2</option>
                    <option value="2021/3">2021/3</option>
                    <option value="2022/1">2022/1</option>
                    <option value="2022/2">2022/2</option>
                </select>
            </div>

            <div class="form-group">
                <label for="expireAt">License expire at</label>
                <input type="date" class="form-control" id="expireAt" placeholder="Expirey date" v-model="form.expireAt">
            </div>

            <div class="form-group">
                <label for="rootKey">RootKey for the product</label>
                <input type="text" class="form-control" id="rootKey" placeholder="RootKey" v-model="form.rootKey">
                <button type="button" class="btn btn-primary mb-2" v-on:click="generateRootKey">Generate Root Key</button>
            </div>

            <div class="form-group">
                <label for="msRevLicense">MS Rev license key</label>
                <input type="text" name="msRevLicense" class="form-control" id="msRevLicense" v-model="form.msRevLicense" readonly>
            </div>

            <div class="form-group">
                <label for="createdAt">MS Rev license created at</label>
                <input type="text" name="createdAt" class="form-control" id="createdAt" v-model="form.createdAt" readonly>
            </div>

            <div class="form-group">
                <label for="requestedAt">Date of last request</label>
                <input type="datetime" class="form-control" id="requestedAt" placeholder="Request date" v-model="form.requestedAt" readonly>
            </div>

            <div class="form-group">
                <label for="mac">MAC address of request</label>
                <input type="text" class="form-control" id="mac" placeholder="Mac address of request" v-model="form.mAC" readonly>
            </div>


            <div class="form-group">
                <button class="btn btn-primary" :disabled="!hasRootKey">Submit</button>
            </div>
        </form>


    </div>
</template>


<script>
    import axios from 'axios';
    import { mapMutations } from 'vuex';
    import { PublicClientApplication } from '@azure/msal-browser';

    export default {
        name: "CreateLicense",
        data() {
            return {
                form: {
                    rootKey: '',
                    company: '',
                    version: '',
                    createdAt: undefined,
                    expireAt: undefined,
                    requestedAt: undefined,
                    mAC: '',
                    msRevLicense: ''
                }
            }
        },
        created() {
            this.$msalInstance = new PublicClientApplication(
                this.$store.state.msalConfig,
            );

            this.$emitter.on(
                'login', function (account) {
                    this.account = account;
                    console.log(this.account);
                }, this);
            this.$emitter.on(
                'logout', () => {
                    console.log('logging out');
                    this.account = undefined;
                }, this);
        },
        mounted() {
            const accounts = this.$msalInstance.getAllAccounts();
            if (accounts.length == 0) {
                return;
            }
            this.account = accounts[0];
            this.$emitter.emit('login', this.account);
        },
        methods: {
            ...mapMutations(['setAccessToken']),
            async submitForm() {
                if (this.$store.state.accessToken == '') {
                    await this.getAccessToken();
                }
                const config = {
                    headers: { Authorization: `Bearer ${this.$store.state.accessToken}`, 'Content-Type': 'application/json' }
                };
                const _this = this;
                axios.post('/license', this.form, config)
                    .then((res) => {
                        //Perform Success Action
                        _this.form.createdAt = res.data.createdAt;
                        _this.form.msRevLicense = res.data.msRevLicense;
                        _this.form.rootKey = res.data.rootKey;
                    })
                    .catch((error) => {
                        if (error.response) {
                            // Request made and server responded
                            console.log(error.response.data);
                            console.log(error.response.status);
                            console.log(error.response.headers);
                        } else if (error.request) {
                            // The request was made but no response was received
                            console.log(error.request);
                        } else {
                            // Something happened in setting up the request that triggered an Error
                            console.log('Error', error.message);
                        }
                    }).finally(() => {
                        //Perform action in always
                    });
            },
            async generateRootKey() {
                if (this.$store.state.accessToken == '') {
                    await this.getAccessToken();
                }
                const config = {
                    headers: { Authorization: `Bearer ${this.$store.state.accessToken}`, 'Content-Type': 'application/json' }
                };
                
                const theForm = JSON.stringify(this.form);
                console.log(theForm);
                const _this = this;
                axios.post('/license/GenerateRootKey', theForm, config)
                    .then((res) => {
                        //Perform Success Action
                        _this.form.rootKey = res.data.rootKey;
                    })
                    .catch((error) => {
                        if (error.response) {
                            // Request made and server responded
                            console.log(error.response.data);
                            console.log(error.response.status);
                            console.log(error.response.headers);
                        } else if (error.request) {
                            // The request was made but no response was received
                            console.log(error.request);
                        } else {
                            // Something happened in setting up the request that triggered an Error
                            console.log('Error', error.message);
                        }
                    }).finally(() => {
                        //Perform action in always
                    });
            },
            async getAccessToken() {


                let request = {
                    scopes: this.$store.state.scope,
                };
                const msalInstance = new PublicClientApplication(
                    this.$store.state.msalConfig,
                );
                try {
                    let tokenResponse = await msalInstance.acquireTokenSilent(request);
                    console.log(tokenResponse);
                    this.$store.commit('setAccessToken', tokenResponse.accessToken);
                } catch (error) {
                    console.error('Silent token acquisition failed. Using interactive mode');
                    let tokenResponse = await msalInstance.acquireTokenPopup(request);
                    console.log(`Access token acquired via interactive auth ${tokenResponse.accessToken}`)
                    this.$store.commit('setAccessToken', tokenResponse.accessToken);
                }
            },

        },
        filters: {
        },
        computed: {
            hasRootKey: function () {
                return this.form.rootKey.length>0;
            }
        },
    }
</script>