<template>
    <header>
        <nav class="navbar navbar-expand-sm navbar-toggleable-sm navbar-light bg-white border-bottom box-shadow mb-3">
            <div class="container">
                <a class="navbar-brand">{{title}}</a>
                <button class="navbar-toggler"
                        type="button"
                        data-toggle="collapse"
                        data-target=".navbar-collapse"
                        aria-label="Toggle navigation"
                        @click="toggle">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="navbar-collapse collapse d-sm-inline-flex flex-sm-row-reverse"
                     v-bind:class="{show: isExpanded}">
                    <ul class="navbar-nav flex-grow">
                        <!--<li class="nav-item">
        <router-link :to="{ name: 'Home' }" class="nav-link text-dark">Home</router-link>
    </li>-->

                        <li class="nav-item" v-if="account">
                            <router-link :to="{ name: 'CreateLicense' }" class="nav-link text-dark">Create License</router-link>
                        </li>
                        <li class="nav-item" v-if="account">
                            <router-link :to="{ name: 'FetchLicenses' }" class="nav-link text-dark">Fetch Licenses</router-link>
                        </li>
                        <li class="nav-item" v-if="account">
                            <router-link :to="{ name: 'CreateOldLicense' }" class="nav-link text-dark">Create OLD License</router-link>
                        </li>
                        <li class="nav-item" v-if="account">
                            <router-link :to="{ name: 'FetchOldLicenses' }" class="nav-link text-dark">Fetch OLD Licenses</router-link>
                        </li>
                        <li class="nav-item" v-if="!account">
                            <a v-if="!account"
                               @click="SignIn"
                               target="_blank"
                               rel="noopener noreferrer"
                               class="nav-link text-dark">
                                <!--<i class="fas fa-sign-in-alt fa-2x" aria-hidden="false"></i>-->
                                Sign In
                            </a>
                        </li>
                        <li class="nav-item" v-else>
                            <a class="nav-link text-dark" @click="SignOut" target="_blank" rel="noopener noreferrer">
                                <!--<i class="fas fa-sign-out-alt fa-2x" aria-hidden="false"></i>-->
                                Sign Out
                            </a>

                        </li>
                        <li class="nav-item" v-if="account">
                        <span class="nav-link text-dark">{{ account.name }}</span></li>
                    </ul>
                </div>
            </div>
        </nav>
    </header>
</template>


<style>
    a.navbar-brand {
        white-space: normal;
        text-align: center;
        word-break: break-all;
    }

    html {
        font-size: 14px;
    }

    @media (min-width: 768px) {
        html {
            font-size: 16px;
        }
    }

    .box-shadow {
        box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
    }
</style>
<script>
    import { PublicClientApplication } from '@azure/msal-browser';

    export default {
        name: "NavMenu",
        data() {
            return {
                account: undefined,
                isExpanded: false,
                title: process.env.VUE_APP_TITLE
            }
        },
        async created() {
            this.$msalInstance = new PublicClientApplication(
                this.$store.state.msalConfig,
            );
        },
        mounted() {
            const accounts = this.$msalInstance.getAllAccounts();
            if (accounts.length == 0) {
                return;
            }
            this.account = accounts[0];
            this.$emitter.emit('login', this.account);
        },
        methods: {
            collapse() {
                this.isExpanded = false;
            },

            toggle() {
                this.isExpanded = !this.isExpanded;
            },
            async SignIn() {
                const loginRequest = {
                    scopes: ["openid", "profile"]}
                await this.$msalInstance
                    .loginPopup(loginRequest)
                    .then(() => {
                        const myAccounts = this.$msalInstance.getAllAccounts();
                        this.account = myAccounts[0];
                        this.$emitter.emit('login', this.account);
                    })
                    .catch(error => {
                        console.error(`error during authentication: ${error}`);
                    });
            },
            async SignOut() {
                await this.$msalInstance
                    .logout({})
                    .then(() => {
                        this.$emitter.emit('logout', 'logging out');
                    })
                    .catch(error => {
                        console.error(error);
                    });
            },
        }
    }
</script>