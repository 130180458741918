<template>
    <h1 id="tableLabel">License data</h1>

    <p>This component fetches license data from the server. <b>Max 1000 rows returned</b></p>
    <div class="form-group">
        <input v-model="customer" placeholder="Company to filter by" type="text" class="form-control" id="customer" />  <button @click="getLicensess">Reload</button>
    </div>
    <p v-if="!licenses"><em>Loading...</em></p>

    <table class='table table-striped' aria-labelledby="tableLabel" v-if="licenses">
        <thead>
            <tr>
                <th>Actions</th>
                <th>Company</th>
                <th>Version</th>
                <th>Root Key</th>
                <th>Expire At</th>
                <th>MsRev License</th>
                <th>Requested At</th>
                <th>MAC</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(license,index) in licenses" v-bind:key="license">
                <td><button @click="deleteRow(index)">delete</button></td>
                <td>{{ license.company }}</td>
                <td>{{ license.version }}</td>
                <td>{{ license.rootKey }}</td>
                <td>{{ license.expireAt }}</td>
                <td>{{ license.msRevLicense }}</td>
                <td>{{ license.requestedAt }}</td>
                <td>{{ license.mac }}</td>
            </tr>
        </tbody>
    </table>
</template>


<script>
    import axios from 'axios'
    import { mapMutations } from 'vuex';
    import { PublicClientApplication } from '@azure/msal-browser';

    export default {
        name: "FetchLicenses",
        data() {
            return {
                account: undefined,
                licenses: [],
                customer: undefined,
            }
        },
        created() {
            this.$msalInstance = new PublicClientApplication(
                this.$store.state.msalConfig,
            );

            this.$emitter.on(
                'login', function (account) {
                    this.account = account;
                    console.log(this.account);
                    this.getLicensess();
                }, this);
            this.$emitter.on(
                'logout', () => {
                    console.log('logging out');
                    this.account = undefined;
                }, this);
        },
        mounted() {
            const accounts = this.$msalInstance.getAllAccounts();
            if (accounts.length == 0) {
                return;
            }
            this.account = accounts[0];
            this.$emitter.emit('login', this.account);
        },
        methods: {
            ...mapMutations(['setAccessToken']),

            async getLicensess() {
                if (this.$store.state.accessToken == '') {
                    await this.getAccessToken();
                }
                const config = {
                    headers: { Authorization: `Bearer ${this.$store.state.accessToken}` }
                };
                if (!this.customer) {
                    axios.get('/license', config)
                        .then((response) => {
                            this.licenses = response.data;
                        })
                        .catch(function (error) {
                            alert(error);
                        });
                }
                else {
                    axios.get('/license/'+this.customer, config)
                        .then((response) => {
                            this.licenses = response.data;
                        })
                        .catch(function (error) {
                            alert(error);
                        });
                }
            },

            async deleteRow(index) {
                if (confirm("Do you really want to delete?")) {
                    if (this.$store.state.accessToken == '') {
                        await this.getAccessToken();
                    }
                    const config = {
                        headers: { Authorization: `Bearer ${this.$store.state.accessToken}` }
                    };
                    const license = this.licenses[index];
                    axios.delete('/license/' + license.rootKey + '/' + license.msRevLicense, config).then((response) => {
                        console.log(response);
                        this.licenses.splice(index, 1);
                    })
                        .catch(function (error) {
                            alert(error);
                        });
                }

            },

            async getAccessToken() {


                let request = {
                    scopes: this.$store.state.scope,
                };
                const msalInstance = new PublicClientApplication(
                    this.$store.state.msalConfig,
                );
                try {
                    let tokenResponse = await msalInstance.acquireTokenSilent(request);
                    console.log(tokenResponse);
                    this.$store.commit('setAccessToken', tokenResponse.accessToken);
                } catch (error) {
                    console.error('Silent token acquisition failed. Using interactive mode');
                    let tokenResponse = await msalInstance.acquireTokenPopup(request);
                    console.log(`Access token acquired via interactive auth ${tokenResponse.accessToken}`)
                    this.$store.commit('setAccessToken', tokenResponse.accessToken);
                }
            },
        },
    }
</script>